import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import BttImg from '@/assets/img/for-every-child/endviolence_bn_img@2x.png';
import BttImgM from '@/assets/img/for-every-child/endviolence_bn_imgM@2x.png';
import Obj1 from '@/assets/img/for-every-child/endviolence_obj1@2x.png';
import Obj2 from '@/assets/img/for-every-child/endviolence_obj2@2x.png';
import Obj3 from '@/assets/img/for-every-child/endviolence_obj3@2x.png';
import Obj4 from '@/assets/img/for-every-child/endviolence_obj4@2x.png';
import Obj5 from '@/assets/img/for-every-child/endviolence_obj5@2x.png';
import WedoImg from '@/assets/img/for-every-child/endviolence_wedo_img@2x.png';
import WedoImgM from '@/assets/img/for-every-child/endviolence_wedo_imgM@2x.png';
import Button from '@/components/Button';
import CampaignCard2, { CampaignData } from '@/components/Card/CampaignCard2';
import Container from '@/components/Container';
import Row from '@/components/Grid/Row';
import Image from '@/components/Image';
import { SectionTop } from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import ProcessList from '@/components/ProcessList';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import BR from '@/components/Typing/BR';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const itemData = [
  {
    id: 1,
    img: Obj1,
    desc: [
      {
        dec: `Every year, <strong>one in two</strong> children around the world suffer from violence.`,
      },
    ],
  },
  {
    id: 2,
    img: Obj2,
    desc: [
      {
        dec: `More than <strong>300 million</strong> children aged 2-4 are physically abused by their carers and parents.`,
      },
    ],
  },
  {
    id: 3,
    img: Obj3,
    desc: [
      {
        dec: `<strong>About 32%</strong> of students aged 11-15 are bullied at school.`,
      },
    ],
  },
  {
    id: 4,
    img: Obj4,
    desc: [
      {
        dec: `<strong>About 36%</strong> of students aged 13-15 have bullied other friends.`,
      },
    ],
  },
  {
    id: 5,
    img: Obj5,
    desc: [
      {
        dec: `<strong>120 million</strong> female children under 20 have experienced sexual harassment and sexual violence.`,
      },
    ],
  },
];
/*
const CampaignData = [
  {
    id: 1,
    pcImage: Card1,
    mobileImage: Card1,
    title: `파트너십 재협약 체결 및 확장`,
    dec: `BHE/BTS와의 파트너십의 또 다른 2년을 기약하는 재협약을 체결했습니다. 짝짝짝! 유니세프한국위원회에서 공식적으로 최초로 글로벌 파트너십으로 확장된 파트너십으로 앞으로의 행보가 기대됩니다. 온라인 협약을 위해 특별히 제작된 협약식 영상도 확인해보세요!`,
    pop: `#Love Myself 캠페인 활동`,
    youtube: `https://youtu.be/xnqgPfrx_wQ`,
  },
  {
    id: 2,
    pcImage: Card2,
    mobileImage: Card2,
    title: `Inspire Awards 2020 수상`,
    dec: `2019년 전 유니세프에서 가장 우수한 파트너십을 선정하는 Inspire Awards에서 LOVE MYSELF 캠페인이 가장 우수한 통합 캠페인 및 이벤트 부분에서 최우수 캠페인으로 선정되었습니다. 아동이 안전한 세상을 위해 참여해주신 모든 후원자님과 팬 분들께 감사드립니다!`,
    pop: `#Love Myself 캠페인 활동`,
    youtube: ``,
  },
  {
    id: 3,
    pcImage: Card1,
    mobileImage: Card1,
    title: `Inspire Awards 2020 수상`,
    dec: `2019년 전 유니세프에서 가장 우수한 파트너십을 선정하는 Inspire Awards에서 LOVE MYSELF 캠페인이 가장 우수한 통합 캠페인 및 이벤트 부분에서 최우수 캠페인으로 선정되었습니다. 아동이 안전한 세상을 위해 참여해주신 모든 후원자님과 팬 분들께 감사드립니다!`,
    pop: `#Love Myself 캠페인 활동`,
    youtube: ``,
  },
];
*/
const SectionSort = styled(Section)`
  padding-bottom: 0 !important;
  .process-ol {
    justify-content: center;
    .list-item {
      width: 33.33%;
      margin-bottom: 124px;

      &:nth-child(1),
      &:nth-child(2) {
        width: 50%;
      }

      .dec-ul {
        max-width: 210px;
        margin: 0 auto;
        margin-top: 25px;

        strong {
          color: #1cabe2;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      .list-item {
        margin-bottom: 70px;
        dt {
          padding: 0;
          .img-wrap {
            max-width: 80px;
          }
        }
        .dec-ul {
          max-width: 120px;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      .list-item {
        width: 50%;

        &:nth-child(1) {
          width: 100%;
        }
      }
    }
  }
`;

const CardFlex = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  align-items: stretch;

  & > li {
    width: 33.33%;
    padding: 0 16px;

    .card {
      height: 100%;
      position: relative;
      background: #fff;
      box-shadow: 24px 14px 40px 0 rgba(0, 0, 0, 0.08);
      border-radius: 0 0 20px 0;
      padding: 40px 32px;
      padding-top: 72px;

      dt {
        display: inline-block;
        background: #1cabe2;
        border-radius: 0 0 16px 0;
        line-height: 56px;
        color: #fff;
        padding: 0 32px;
        position: absolute;
        left: 0;
        top: -16px;
      }
    }

    ${breakpoint(`tablet`)} {
      width: 100%;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
const SectionInfluence = styled(Section)``;

const SectionWedo = styled(Section)`
  ${SectionHeader} {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 316px;
      height: 316px;
      border-radius: 100%;
      background: #1cabe2;
      position: absolute;
      left: -116px;
      top: -134px;
      z-index: -1;
    }

    ${breakpoint(1160)} {
      &::before {
        width: 27.24vw;
        height: 27.24vw;
        left: -10vw;
        top: -11.55vw;
      }
    }
  }
  .flex-wrap {
    padding-right: 48.6%;
    position: relative;
    flex-wrap: wrap;

    .blit-list {
      width: 100%;
      font-weight: 700;
      & > li {
        margin-bottom: 16px;
        line-height: 1.7;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .col-img {
      width: 48.6%;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    ${breakpoint(`tablet`)} {
      padding-right: 0;

      .col-img {
        width: 100%;
        position: relative;
        margin-top: 8px;
      }
    }
  }
`;

const SectionCampaign = styled(Section)`
  padding-bottom: 234px !important;

  ${breakpoint(`tablet`)} {
    padding-bottom: 120px !important;
  }
`;

const SectionBtt = styled(Section)`
  background: #1cabe2;
  padding-top: 46px;
  header {
    ${Tit} {
      line-height: 1.5;
    }
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 72px;
    position: relative;

    .col-dec {
      width: 59%;
      padding-right: 20px;

      .header-dec {
        color: #fff;
        margin-top: 32px;
        line-height: 1.7;
      }
      button {
        margin-top: 64px;
        max-width: 256px;
        width: 100%;
      }
    }

    .col-img {
      position: absolute;
      bottom: 0;
      right: -261px;
      width: 683px;
    }
  }
  ${breakpoint(1160)} {
    .flex {
      .col-img {
        right: -22.5vw;
        width: 58.88vw;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .flex {
      padding-bottom: 0;
      .col-dec {
        width: 100%;
        padding-right: 0;
        position: relative;
        z-index: 1;

        .header-dec {
          line-height: 2;
        }
        button {
          max-width: 152px;
        }
      }
      .col-img {
        position: relative;
        text-align: right;
        right: -20px;
        margin-top: -46px;
        width: 100%;
      }
    }
  }
`;

const EnEndviolencePage: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['EV02'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          title: story.subject,
          dec: story.contents,
          image: story.imgAttGrpNoTn,
          pop: `#Love Myself 캠페인 활동`,
          youtube: story.optData1,
          thumYoutube: story.optData2,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
  }, [loadSlideData]);

  return (
    <LayoutWithTitle
      location={location}
      paddingBtt
      title="#ENDviolence"
      description="for every child, safety"
    >
      <SectionTop
        css={`
          padding-top: 32px;
        `}
      >
        <Container>
          <Row
            justifyContent="flex-end"
            css={`
              margin-bottom: 24px;
            `}
          >
            <Button
              css={`
                padding: 0 30px;
                height: 40px;
              `}
              size="sm"
              onClick={() =>
                navigate(`/what-we-do/for-every-child/endviolence`)
              }
            >
              KOR
            </Button>
          </Row>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  BTS supports #ENDviolence.
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                In November 2017, BTS and Big Hit Music signed an official
                partnership with Korean Committee for UNICEF, We have tried to
                advocate and prevent Violence against children and adolescents
                around the world. Love Myself campaign supports a global project
                #ENDviolence which is launched by UNICEF in 2013 to create a
                safe world without violence against children and adolescents.
                Donations raised by Love Myself campaign are used to support
                children and adolescents victimized by violence and to urge the
                community to overhaul the system to prevent violence. During BTS
                World Tour (Japan, United States, Canada, United Kingdom,
                Hongkong, and Thailand etc.) which has been held since April
                2018, BTS has advocated #ENDviolence aligning with national
                UNICEF committees and country offices.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="xnqgPfrx_wQ" />
        </PageComponent>
      </SectionTop>

      <SectionSort className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">Children and adolescents exposed to violence</Tit>
            </h2>
          </SectionHeader>

          <ProcessList itemData={itemData} className="ProcessList" />
        </Container>
      </SectionSort>

      <SectionInfluence className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <h2>
              <Tit size="s1">
                The result of violence on the future of children and adolescents
              </Tit>
            </h2>
          </SectionHeader>
          <CardFlex>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s3">Health problems</Tit>
                </dt>
                <dd>
                  <p className="dec">
                    12% of children who experienced physical violence were
                    exposed to physical disabilities, 17% to anger disorder, and
                    21% to alcoholism.
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s3">Educational problems</Tit>
                </dt>
                <dd>
                  <p className="dec">
                    It is estimated that 13% of children who are exposed to all
                    kinds of violence fail to graduate from school.
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s3">Personality development problem</Tit>
                </dt>
                <dd>
                  <p className="dec">
                    Adults who are exposed to violence before the age of 18
                    experience suicidal impulse 30 times more than otherwise.
                  </p>
                </dd>
              </dl>
            </li>
          </CardFlex>
        </Container>
      </SectionInfluence>

      <SectionWedo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                What UNICEF does <br />
                for #ENDviolence
              </Tit>
            </h2>
          </SectionHeader>
          <div className="flex-wrap flex">
            <ul className="blit-list">
              <li>Efficient fundraising and support</li>
              <li>Plan and share efficient business methods</li>
              <li>
                Psychotherapy programs for children and adolescents victimized
                by violence
              </li>
              <li>
                Reform systems and legislations to protect students from
                violence
              </li>
              <li>Establish a safe social network</li>
              <li>Support for parents, carers and teachers</li>
            </ul>
            <div className="col-img">
              <Image pcSrc={WedoImg} mobileSrc={WedoImgM} />
            </div>
          </div>
        </Container>
      </SectionWedo>

      <SectionCampaign className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">#Love Myself Activities</Tit>
            </h2>
          </SectionHeader>
          {slideData.length > 0 && (
            <CampaignCard2 campaigns={slideData} overflowHidden locale="en" />
          )}
        </Container>
      </SectionCampaign>

      <SectionBtt>
        <Container>
          <header>
            <h2
              css={`
                font-size: 40px;
                line-height: 1.5;
                letter-spacing: -2px;
                font-weight: normal;
                color: #ffffff;
                ${breakpoint(`mobile`)} {
                  font-size: 24px;
                  font-weight: normal;
                  line-height: 1.58;
                  letter-spacing: -1.2px;
                }
              `}
            >
              Your valuable support can help children <BR only="desktop" />
              and adolescents suffering from violence.
            </h2>
          </header>
          <div className="flex">
            <div className="col-dec">
              <p className="header-dec">
                UNICEF is working with governments, NGOs, and partner
                organizations in each country to stop violence against children
                and adolescents around the world. Please join us for the
                children and adolescents who are trembling in the agony of fear
                of violence at this moment. Your warm support and interest will
                make the safer schools and communities. Your warm interest and
                support can make a strong fence and a community that can protect
                children and adolescents.
              </p>

              <Button
                color="yellow"
                onClick={() =>
                  navigate(`/what-we-do/for-every-child/endviolence/en/donate`)
                }
              >
                Donate now
              </Button>
            </div>
            <div className="col-img">
              <Image pcSrc={BttImg} mobileSrc={BttImgM} />
            </div>
          </div>
        </Container>
      </SectionBtt>
    </LayoutWithTitle>
  );
};

export default EnEndviolencePage;
